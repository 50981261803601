import { PROPERTIES_TAGS_CODES } from '~/constants'
import type { Property, PropertyTag } from '~/types/properties'

export default (tagsList?: PropertyTag[], tagIds?: Property['tag_ids']) => {
  if (!tagIds?.length || !tagsList?.length) return []

  const tagListFiltered = tagsList.filter(({ is_crm: isCRM }) => isCRM)

  const tagIdsFiltered = tagIds.filter((tagId) => tagListFiltered.find((tag) => tag.id === tagId))

  const getTagName = (tag: PropertyTag) => {
    const TAGS_NAME = {
      [PROPERTIES_TAGS_CODES.PROPERTY_EXCLUSIVE]: 'Exclusive',
    }

    return TAGS_NAME[tag.code as PROPERTIES_TAGS_CODES] || tag.name
  }

  const getTagIcon = (id: PropertyTag['id']) => {
    const code = tagListFiltered?.find((tag) => tag.id === id)?.code

    const ICONS_MAP = {
      [PROPERTIES_TAGS_CODES.PROPERTY_EXCLUSIVE]: 'diamond',
    }

    return ICONS_MAP[code as PROPERTIES_TAGS_CODES]
  }

  return tagIdsFiltered.map((tagId) => {
    const tag = tagListFiltered.find((tag) => tag.id === tagId)

    if (!tag) {
      throw new Error(`Tag with id ${tagId} not found`)
    }

    return { ...tag, icon: getTagIcon(tag.id), name: getTagName(tag) }
  })
}
