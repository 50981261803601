import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import type { PropertyTypes } from '@/types'
import { MEASURE_LIST_LABELS } from '~/constants'

export const nameToColorPropertyMap: { [key: string]: string } = {
  'On Sale': '#3957D3',
  Launch: '#F0C724',
  'Presale (EOI)': '#43CF58',
  Announcement: '#F6865F',
  Pending: '#EE4925',
  'Sold Out': '#CFCFD0',
}

const codeToNameMap: { [key: string]: string } = {
  rooms_studio: 'Studio',
  rooms_1: '1 bedroom',
  rooms_2: '2 bedroom',
  rooms_3: '3 bedroom',
  rooms_4: '4 bedroom',
  rooms_5: '5 bedroom',
  rooms_6: '6 bedroom',
  rooms_7: '7 bedroom',
}

const nameToCodeMap: { [key: string]: string } = {
  Studio: 'rooms_studio',
  '1 Bedroom': 'rooms_1',
  '1 BR': 'rooms_1',
  '2 Bedroom': 'rooms_2',
  '2 BR': 'rooms_2',
  '3 Bedroom': 'rooms_3',
  '3 BR': 'rooms_3',
  '4 Bedroom': 'rooms_4',
  '4 BR': 'rooms_4',
  '5 Bedroom': 'rooms_5',
  '5 BR': 'rooms_5',
  '6 Bedroom': 'rooms_6',
  '6 BR': 'rooms_6',
  '7 Bedroom': 'rooms_7',
  '7 BR': 'rooms_7',
}

export const getPropertyUnits = (items: PropertyTypes.Apartment[]): any => {
  const apartments = items.map((item: PropertyTypes.Apartment) => {
    return {
      key: item.rooms_count.en.length ? nameToCodeMap[item.rooms_count.en] : nameToCodeMap[item.title.en],
      ...item,
    }
  })

  const units = map(
    groupBy(apartments, (apartment) => apartment.key),
    (value, key) => ({
      name: key,
      title: codeToNameMap[key],
      price_min_base: Math.min(...value.map((apartment) => parseFloat(apartment.price.min.base?.value))),
      price_min_user: Math.min(...value.map((apartment) => parseFloat(apartment.price.min.user?.value || '0'))),
      area_min: Math.min(
        ...value.map((apartment) =>
          parseFloat(
            apartment.area_min
              ? apartment.area_min[useGetUserMeasure() === MEASURE_LIST_LABELS.METRIC ? 'm2' : 'ft2']
              : '0'
          )
        )
      ),
      area_max: Math.max(
        ...value.map((apartment) =>
          parseFloat(
            apartment.area_max
              ? apartment.area_max[useGetUserMeasure() === MEASURE_LIST_LABELS.METRIC ? 'm2' : 'ft2']
              : '0'
          )
        )
      ),
      count: value.reduce((sum, apartment) => sum + parseInt(apartment.sale_units_count, 10), 0),
      currency: value[0].price.currency,
      baseCurrency: value[0].price.min.base?.currency,
      userCurrency: value[0].price.min.user?.currency,
      items: value,
    })
  )
  const sortOrder = Object.keys(codeToNameMap)
  const sortedUnits = units.sort((a, b) => {
    return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
  })

  return sortedUnits
}
